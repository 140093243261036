<template>
  <div class="admin admin-tokens view">
    <h1 class="page-heading">
      <svg class="icon">
        <use xlink:href="#gear"></use>
      </svg>
      <span>
        {{ $t('authenticationTokens') }}
      </span>
    </h1>

    <div class="headSection">
      <el-button @click="addNewToken" round type="primary" icon="si-plus">
        {{ $t('addNewToken') }}
      </el-button>

      <div class="header-search">
        <el-input v-model="search" size="medium" :placeholder="$t('typeToSearch')" />
      </div>
    </div>

    <main class="main-container panel">
      <!-- Save token alert message -->
      <el-alert v-if="token" :title="SaveTokenAlert" type="success">
        <!-- show token here -->
        <input type="text" id="token" :value="token" style="width: 90%" />
        <el-tooltip class="item" effect="light" content="Copy Token" placement="right-start">
          <el-button @click.stop.prevent="copyTokenString" class="btnCopyToken">
            <i class="si-clipboard"></i>
          </el-button>
        </el-tooltip>
      </el-alert>

      <template>
        <el-table :data="tokens.filter((tokens) => !search || tokens.name.toLowerCase().includes(search.toLowerCase()))" :default-sort="{ prop: 'modified', order: 'descending' }" style="width: 100%" v-if="tokens">
          <!-- Name column -->
          <el-table-column prop="name" :label="$t('name')"> </el-table-column>
          <!-- Permissions column -->
          <el-table-column prop="permissions" :label="$t('permissions')">
            <template slot-scope="scope">
              <div class="formattedPermissions" v-for="perms in scope.row.permissions" :key="perms.id">
                {{ perms }}
              </div>
            </template>
          </el-table-column>
          <!-- Last Used column -->
          <el-table-column prop="modified" :label="$t('modified')" :formatter="dateFormatter"> </el-table-column>
          <!-- Delete column -->
          <el-table-column width="100vw" align="left">
            <template slot-scope="scope">
              <el-button @click="deleteToken(scope.row)" size="mini" type="danger">
                <i class="button-icon si-trash"> </i><span class="button-text">{{ $t('delete') }}</span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </main>
    <token-modal :visible="modalOpen" @close="modalOpen = false" @save="createToken" />
  </div>
</template>

<script>
import api from '../api'
import { Table, TableColumn, Button, Input, Alert, Message, Tooltip, MessageBox } from 'element-ui'
import Loading from '../mixins/Loading'
import TokenModal from '../components/TokenModal'

export default {
  name: 'AdminTokens',
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-input': Input,
    'token-modal': TokenModal,
    'el-alert': Alert,
    'el-tooltip': Tooltip
  },
  created() {
    this.loadTokens()
  },
  data() {
    return {
      tokens: null, // Once this.loadTokens has run this will store a list of tokens for the current site
      search: '',
      tokendata: [],
      modalOpen: false,
      SaveTokenAlert: '',
      token: ''
    }
  },
  computed: {},
  methods: {
    loadTokens() {
      this.startLoading()
      return api
        .getTokens()
        .then((response) => {
          this.tokens = response.data
          this.stopLoading()
        })
        .catch(() => {
          this.stopLoading()
        })
    },
    deleteToken(selectedRow) {
      return (
        MessageBox.confirm(this.$t('deleteTokenConfirmMessage'), this.$t('warning'), {
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        })
          //if accepted, send api request
          .then(() => {
            return api
              .deleteToken(selectedRow.id)
              .then(() => {
                this.loadTokens()
                Message({
                  type: 'success',
                  message: this.$t('deleteCompleted')
                })
              })
              .catch(() => {
                Message({
                  type: 'info',
                  message: this.$t('deleteCancelled')
                })
              })
          })
          .catch(() => {
            // User has selected cancel, no further action required
          })
      )
    },
    addNewToken() {
      //open modal
      this.modalOpen = true
    },
    dateFormatter(row) {
      return this.$d(new Date(row.modified), 'mediumWithTime')
    },
    createToken(formData) {
      return api
        .createToken(formData)
        .then((tokenData) => {
          // The API returns the tokenData including the value
          this.loadTokens()
          this.modalOpen = false
          this.token = tokenData.value
          this.SaveTokenAlert = this.$t('copyAndSaveYourToken')
        })
        .catch(() => {
          Message.error(this.$t('errorCreatingToken'))
        })
    },
    copyTokenString() {
      let TokenStringToCopy = document.querySelector('#token')
      TokenStringToCopy.setAttribute('type', 'text')
      TokenStringToCopy.select()
      document.execCommand('copy')
        ? Message({
            message: this.$t('copyingTokenSuccessful'),
            type: 'success'
          })
        : Message.error(this.$t('errorCopyingToken'))

      window.getSelection().removeAllRanges()
    }
  },
  watch: {
    visible() {
      if (!this.modalOpen) {
        this.formData = {
          name: null,
          claimValue: '',
          permissions: []
        }
        return
      }
    }
  },
  mixins: [Loading]
}
</script>

<style lang="less" scoped>
.btnCopyToken {
  font-size: 2.5vh;
  padding: 0.5vw;
  text-align: right;
}

.button-text {
  margin: 0 !important; // Don't add a margin between the icon and text
}

.button-icon {
  display: none;
}

.headSection {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 900px) {
  .button-text {
    display: none;
  }

  .button-icon {
    display: inline;
  }
}
</style>
