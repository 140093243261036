<template>
  <div class="site food-vendors view">
    <h1 class="page-heading">
      <svg class="icon">
        <use xlink:href="#food"></use>
      </svg>
      <span>
        {{ $t('foodAndDrink') }}
      </span>
    </h1>

    <div class="page food">
      <header>
        <el-button v-if="hasAnyPermission(['write:fandb'])" @click="createVendor" round icon="si-plus" type="primary">
          {{ $t('createVendor') }}
        </el-button>

        <el-button @click="setAllModalOpen = true" round icon="si-edit">
          {{ $t('setAllVendors') }}
        </el-button>

        <el-button @click="messageModalOpen = true" round v-if="hasAnyPermission(['write:message', 'delete:message'])">
          {{ $t('managePresetMessages') }}
        </el-button>
      </header>

      <main class="page-body">
        <template v-if="activeVendors.length">
          <h2 class="resource-type">{{ $t('activeFood') }}</h2>

          <div class="status-wrapper">
            <vendor-status-panel v-for="vendor in activeVendors" :key="vendor.id" :id="vendor.id" :name="vendor.name" :value="vendor.status" :messages="statusMessages" :edited="statusChanges[vendor.id] ? true : false" @input="updateStatus" @edit="editVendor" />
          </div>
        </template>

        <template v-if="inactiveVendors.length">
          <h2 class="resource-type">{{ $t('inactiveFood') }}</h2>

          <div class="status-wrapper">
            <vendor-status-panel v-for="vendor in inactiveVendors" :key="vendor.id" :id="vendor.id" :name="vendor.name" :value="vendor.status" :messages="statusMessages" :edited="statusChanges[vendor.id] ? true : false" @input="updateStatus" @edit="editVendor" />
          </div>
        </template>
      </main>
    </div>

    <footer class="page-footer">
      <div class="footer-inner">
        <el-button type="primary" @click="saveStatuses" :disabled="!statusUpdates">{{ $t('save') }}</el-button>

        <span class="unsaved-notice" v-if="statusUpdates">
          <i class="si-info-circle"></i><span>{{ $t('youHaveUnsavedChanges') }}</span>
        </span>
      </div>
    </footer>

    <vendor-modal :visible="modalOpen" :vendor="editedVendor" :editing="modalEditing" @close="modalOpen = false" @save="saveVendor" />

    <set-all-vendors-modal :visible="setAllModalOpen" :messages="statusMessages" @close="setAllModalOpen = false" @save="setAll" />

    <message-modal :visible="messageModalOpen" :messages="statusMessages" type="foodVendors" @close="messageModalOpen = false" @save="handleStatusMessageUpdate" />
  </div>
</template>

<script>
import api from '../api'
import { mapState, mapGetters } from 'vuex'
import { Message } from 'element-ui'
import Loading from '../mixins/Loading'
import VendorStatusPanel from '../components/food/VendorStatusPanel'
import VendorModal from '../components/food/VendorModal'
import SetAllVendorsModal from '../components/food/SetAllVendorsModal'
import MessageModal from '../components/MessageModal'

export default {
  name: 'FoodVendors',
  created() {
    return Promise.all([this.loadVendors(), this.loadStatusMessages()])
  },
  components: {
    'vendor-status-panel': VendorStatusPanel,
    'vendor-modal': VendorModal,
    'set-all-vendors-modal': SetAllVendorsModal,
    'message-modal': MessageModal
  },
  computed: {
    statusUpdates() {
      // Returns a boolean indicating if any statuses have been updated
      return Object.keys(this.statusChanges).length > 0 ? true : false
    },
    activeVendors() {
      // Return a list of active rides
      return this.vendors ? this.vendors.filter((vendor) => vendor.active) : []
    },
    inactiveVendors() {
      // Return a list of inactive rides
      return this.vendors ? this.vendors.filter((vendor) => !vendor.active) : []
    },
    editedVendor() {
      // Returns the vendor currently being edited in the modal
      if (!this.vendors || !this.currentId) return null
      return this.vendors.find((vendor) => vendor.id === this.currentId)
    },
    ...mapState(['selectedSiteId']),
    ...mapGetters(['hasAnyPermission', 'blipSite'])
  },
  data() {
    return {
      vendors: [],
      modalEditing: false,
      setAllModalOpen: false,
      statusMessages: [], // Available status messages
      messageModalOpen: false, // Is the 'Manage vendor statuses' modal open?
      currentId: null, // When set indicates the vendor currently being edited
      modalOpen: false,
      statusChanges: {} // Will store updated statuses before they are saved
    }
  },
  methods: {
    handleStatusMessageUpdate(updatedMessages, deletedMessages) {
      // Save any changes to the ride status messages

      // Messages will be an array of message objects. Some of these may have an
      // ID (indicating they need to be updated) while some will not (indicating
      // they need to be created)
      return Promise.all([this.updateStatusMessages(updatedMessages), this.deleteStatusMessages(deletedMessages)])
        .then(() => {
          // Reload the status messages
          return this.loadStatusMessages()
        })
        .then(() => {
          Message({
            message: this.$t('updatedPresets'),
            type: 'success'
          })
        })
        .catch(() => {
          // If we've hit any unexpected errors saving the messages display a
          // notification to the user
          Message({
            message: this.$t('errorUpdatingStatusMessages'),
            type: 'error'
          })
        })
        .finally(() => {
          this.messageModalOpen = false
        })
    },
    updateStatusMessages(updatedMessages) {
      // Iterate through the updatedMessages array and send update requests for
      // messages with IDs and create requests for messages without

      var requests = updatedMessages.map((message) => {
        if (message.id) {
          // This is an existing massge, so update it.
          return api.updateStatus(this.selectedSiteId, message.id, message)
        } else {
          // This is a new message, so create it.
          return api.createStatus(this.selectedSiteId, message)
        }
      })

      return Promise.all(requests)
    },
    deleteStatusMessages(deletedMessages) {
      // Send requests to the API to delete the messages in the deletedMsssages
      // param. Note that some messages might not have an ID (indicating a
      // message was created and deleted without ever being saved) and may
      // simply be ignored.

      // Build an array of deletion requests
      var requests = deletedMessages
        .filter((message) => {
          return message.id
        })
        .map((message) => {
          console.log(message)
          return api.deleteStatus(this.selectedSiteId, message.id)
        })

      // Return a promise for the request array
      return Promise.all(requests)
    },
    loadStatusMessages() {
      return api.getStatuses(this.selectedSiteId, { type: 'foodVendors' }).then((response) => {
        this.statusMessages = response.data
      })
    },
    setAll(status) {
      // Set all the ride statuses to match sthe status property
      if (!status) return

      this.vendors.forEach((vendor) => {
        var newStatus = Object.assign({}, vendor.status, status)
        vendor.status = newStatus
        this.$set(this.statusChanges, vendor.id, newStatus)
      })

      Message({
        message: this.$t('allVendorStatusesUpdated'),
        type: 'success'
      })

      this.setAllModalOpen = false
    },
    createVendor() {
      // Open the modal, allowing the user to create a new ride
      this.modalEditing = false
      this.modalOpen = true
      this.currentId = null
    },
    saveVendor(editing, vendorData) {
      // Update the ride described in the rideData parameter
      //console.log(rideData);
      this.modalOpen = false
      var { id, status, ...vendor } = vendorData

      var request

      // Create an update or create request depending on if we are editing an
      // exisiting ride (editing is true)
      if (editing) {
        request = api.updateVendor(this.selectedSiteId, id, vendor)
      } else {
        request = api.createVendor(this.selectedSiteId, vendor)
      }

      this.startLoading()
      return request
        .then(() => {
          var message

          if (editing) {
            message = this.$t('savedChangesToVendor', { vendorName: vendor.name })
          } else {
            message = this.$t('createdVendor', { vendorName: vendor.name })
          }

          Message({
            message: message,
            type: 'success'
          })

          return this.loadVendors()
        })
        .catch(() => {
          Message({
            message: this.$t('errorSavingVendors'),
            type: 'error'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    loadVendors() {
      // laod the vendors for this site from the API
      this.startLoading()
      return api
        .getVendors(this.selectedSiteId, { include: ['status'] })
        .then((response) => {
          this.vendors = response.data
          this.stopLoading()
        })
        .catch(() => {
          this.stopLoading()
          Message({
            message: this.$t('errorLoadingVendors'),
            type: 'error'
          })
        })
    },
    updateStatus(status, id) {
      // Find the ride record to update
      var record = this.vendors.find((vendor) => vendor.id === id)

      if (record) {
        record.status = status
        this.$set(this.statusChanges, id, status)
      }
    },
    saveStatuses() {
      // Create a request for each updatedStatus
      var requests = []

      for (var vendorId in this.statusChanges) {
        let request = api.setVendorStatus(this.selectedSiteId, vendorId, this.statusChanges[vendorId])
        requests.push(request)
      }

      // Wait for the requests to complete
      return Promise.all(requests)
        .then(() => {
          // Display success message
          Message({
            message: this.$t('updatedVendorStatuses'),
            type: 'success'
          })

          // Reset the statusChanges object
          this.statusChanges = {}
        })
        .catch(() => {
          // Display error message
          Message({
            message: this.$t('errorUpdatingVendorStatuses'),
            type: 'error'
          })
        })
    },
    editVendor(id) {
      // Open a modal allowing the user to edit an individual ride
      this.currentId = id
      this.modalEditing = true
      this.modalOpen = true
    }
  },
  mixins: [Loading]
}
</script>

<style lang="less" scoped>
.food-vendors {
  header {
    margin-bottom: 1rem;

    h2 {
      color: white;
    }
  }

  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.page {
  flex-grow: 1;
}
</style>
