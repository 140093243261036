<template>
  <div class="admin admin-users view">
    <h1 class="page-heading">
      <svg class="icon">
        <use xlink:href="#gear"></use>
      </svg>
      <span>
        {{ $t('users') }}
      </span>
    </h1>
  </div>
</template>

<script>
export default {
  name: 'AdminUsers'
}
</script>
