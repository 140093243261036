var render = function render(){var _vm=this,_c=_vm._self._c;return _c('bt-modal',{attrs:{"visible":_vm.visible,"title":_vm.modalTitle},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('el-form',{ref:"form",attrs:{"rules":_vm.rules,"model":_vm.formData}},[(!_vm.override)?_c('el-form-item',{staticStyle:{"margin-bottom":"2rem"},attrs:{"prop":"name","label":_vm.$t('name')}},[_c('el-input',{model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1):_vm._e(),_c('table',[_c('tr',[_c('th'),_c('th',[_vm._v(" "+_vm._s(_vm.$t('from'))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t('to'))+" ")])]),_vm._l((_vm.formData.days),function(day,idx){return _c('tr',{key:day.idx},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(_vm.$t(day.name)))]),_c('td',[_c('el-form-item',{attrs:{"prop":'days.' + idx + '.opens'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"screen-reader-text"},[_vm._v(_vm._s(_vm.$t('from')))])]},proxy:true}],null,true)},[_c('el-time-select',{attrs:{"clearable":false,"picker-options":{
                  start: '00:00',
                  step: '00:15',
                  end: day.closes,
                },"placeholder":_vm.$t('opens')},model:{value:(day.opens),callback:function ($$v) {_vm.$set(day, "opens", $$v)},expression:"day.opens"}})],1)],1),_c('td',[_c('el-form-item',{attrs:{"prop":'days.' + idx + '.closes'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"screen-reader-text"},[_vm._v(_vm._s(_vm.$t('to')))])]},proxy:true}],null,true)},[_c('el-time-select',{attrs:{"clearable":false,"picker-options":{
                  start: day.opens,
                  step: '00:15',
                  end: '23:45',
                },"placeholder":_vm.$t('closes')},model:{value:(day.closes),callback:function ($$v) {_vm.$set(day, "closes", $$v)},expression:"day.closes"}})],1)],1)])})],2)],1)]},proxy:true},{key:"footer",fn:function(){return [(!_vm.override)?_c('el-button',{attrs:{"type":"success","disabled":!_vm.unsavedChanges},on:{"click":function($event){return _vm.save(false)}}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e(),(_vm.override)?_c('el-button',{attrs:{"type":"success","disabled":!_vm.unsavedChanges},on:{"click":function($event){return _vm.save(true)}}},[_vm._v(" "+_vm._s(_vm.$t('saveTemporaryOverride'))+" ")]):_vm._e(),_c('el-button',{on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('close'))+" ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }