<template>
  <div class="admin admin-user-types view">
    <h1 class="page-heading">
      <svg class="icon">
        <use xlink:href="#gear"></use>
      </svg>
      <span>
        {{ $t('userTypes') }}
      </span>
    </h1>

    <!-- head section with add button here  -->
    <div class="headSection">
      <el-button @click="addNewUserType" round type="primary" icon="si-plus">
        {{ $t('addUserType') }}
      </el-button>

      <!-- search here -->
      <div class="header-search">
        <el-input v-model="search" size="medium" :placeholder="$t('typeToSearch')" />
      </div>
    </div>

    <main class="main-container panel">
      <!-- table template for list here -->
      <template>
        <el-table :data="userTypes.filter((userTypes) => !search || userTypes.name.toLowerCase().includes(search.toLowerCase()))" :default-sort="{ prop: 'modified', order: 'descending' }" style="width: 100%" v-if="userTypes">
          <!-- Name column -->
          <el-table-column prop="name" :label="$t('name')"> </el-table-column>

          <!-- Created column -->
          <el-table-column prop="created" :label="$t('created')" :formatter="dateFormatter"> </el-table-column>

          <!-- Modified column -->
          <el-table-column prop="modified" :label="$t('modified')" :formatter="dateFormatter"> </el-table-column>
          <!-- Acive switch -->
          <el-table-column :label="$t('active')" prop="active" width="70vw" align="left">
            <template slot-scope="scope">
              <!-- if userType is admin then no switch shows -->
              <el-switch disabled v-if="scope.row.name != 'admin'" :value="scope.row.active" />
            </template>
            />
          </el-table-column>
          <!-- edit button -->
          <el-table-column width="90vw" align="left">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="light" :content="$t('editUserType')" placement="right">
                <el-button v-if="scope.row.name != 'admin'" size="mini" @click="editUserType(scope.row)">
                  <i class="button-icon si-edit"></i>
                  <span class="button-text">{{ $t('edit') }}</span>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>

          <!-- delete button -->
          <el-table-column width="150vw" align="left">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="light" :content="$t('deleteUserType')" placement="right">
                <el-button v-if="scope.row.name != 'admin'" size="mini" type="danger" @click="deleteUserType(scope.row)">
                  <i class="button-icon si-trash"></i>
                  <span class="button-text">{{ $t('delete') }}</span>
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </main>
    <!-- modal here -->
    <user-type-add-modal :visible="modalAddUserTypeOpen" @close="modalAddUserTypeOpen = false" @save="createUserType" :claimValues="claimValues" />
    <user-type-edit-modal :visible="modalEditUserTypeOpen" @close="modalEditUserTypeOpen = false" @save="updateUserType" :userType="selectedUserType" />
  </div>
</template>

<script>
import api from '../api'
import { Table, TableColumn, Button, Input, Tooltip, Message, MessageBox, Switch } from 'element-ui'
import Loading from '../mixins/Loading'
import UserTypeAddModal from '../components/user_types/UserTypeAddModal'
import UserTypeEditModal from '../components/user_types/UserTypeEditModal'

export default {
  created() {
    this.loadUserTypes()
  },
  data() {
    return {
      userTypes: null, // Once this.loadUserTypes has run this will store a list of userTypes for the current site
      modalAddUserTypeOpen: false,
      modalEditUserTypeOpen: false,
      search: '',
      userType: null,
      selectedUserType: null
    }
  },
  name: 'AdminUserTypes',
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    'el-input': Input,
    'user-type-add-modal': UserTypeAddModal,
    'user-type-edit-modal': UserTypeEditModal,
    'el-tooltip': Tooltip,
    'el-switch': Switch
  },
  computed: {
    claimValues() {
      if (!this.userTypes) return []
      // get an array of existing values in the db of claimMalue to
      // avoid saving duplicate
      // if there is nothing in the table return empty array
      var x = this.userTypes.map((type) => type.claimValue)
      return x
    }
  },
  methods: {
    loadUserTypes() {
      this.startLoading()
      return api
        .getUserTypes()
        .then((response) => {
          this.userTypes = response.data
          this.stopLoading()
        })
        .catch(() => {
          this.stopLoading()
        })
    },
    addNewUserType() {
      //open modal
      this.modalAddUserTypeOpen = true
    },
    editUserType(selectedUserType) {
      //assign selectedUserType in a reactive way
      //open modal
      this.selectedUserType = selectedUserType
      this.modalEditUserTypeOpen = true
    },
    dateFormatter(row) {
      // return this.$d(row.modified, 'short')
      return this.$d(new Date(row.modified), 'mediumWithTime')
    },
    createUserType(formData) {
      api
        .createUserType(formData)
        .then((userTypeData) => {
          this.loadUserTypes()
          this.modalAddUserTypeOpen = false
          this.userType = userTypeData.value
          Message({
            message: this.$t('newUserTypeSuccessfullySaved'),
            type: 'success'
          })
        })
        .catch(() => {
          Message.error(this.$t('errorAddingUserType'))
        })
    },
    updateUserType(formData) {
      if (!formData) return
      api
        .updateUserType(formData.id, formData)
        .then((userTypeData) => {
          this.loadUserTypes()
          this.modalEditUserTypeOpen = false
          this.userType = userTypeData.value
          Message({
            message: this.$t('userTypeSuccessfullyEdited'),
            type: 'success'
          })
        })
        .catch(() => {
          Message.error(this.$t('errorEditingUserType'))
        })
    },
    deleteUserType(selectedRow) {
      return MessageBox.confirm(this.$t('deleteUserTypeConfirmMessage'), this.$t('warning'), {
        confirmButtonText: this.$t('ok'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      })
        .then(() => {
          api
            .deleteUserType(selectedRow.id)
            .then(() => {
              this.loadUserTypes()

              Message({
                message: this.$t('deleteCompleted'),
                type: 'success'
              })
            })
            .catch(() => {
              Message({
                message: this.$t('deleteCancelled'),
                type: 'info'
              })
            })
        })
        .catch(() => {
          // User has selected cancel, no further action required
        })
    }
  },
  mixins: [Loading]
}
</script>
<style lang="less" scoped>
.el-table /deep/ .cell {
  padding: 0.5vw;
}

.button-text {
  margin: 0 !important; // Don't add a margin between the icon and text
}

.button-icon {
  display: none;
}

.headSection {
  margin-bottom: 1rem;
}

@media only screen and (max-width: 900px) {
  .button-text {
    display: none;
  }

  .button-icon {
    display: inline;
  }
}
</style>
