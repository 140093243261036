<template>
  <bt-menu :label="$t('mainMenu')" :menuVisible="menuVisible" @close="$emit('close')">
    <template v-slot:header>
      <div class="logo-wrapper">
        <img src="../assets/img/merlin-logo-white.png" width="108" height="85" />
      </div>
    </template>

    <bt-menu-item :to="{ name: 'dashboard', params: { siteId: selectedSiteId } }">
      <svg class="icon">
        <use xlink:href="#dashboard"></use>
      </svg>
      <span>{{ $t('dashboard') }}</span>
    </bt-menu-item>

    <bt-menu-item :to="{ name: 'rides', params: { siteId: selectedSiteId } }" v-if="hasAnyPermission(['read:ride', 'write:ride', 'delete:ride']) && currentSite && currentSite.siteFeatures && currentSite.siteFeatures.rides">
      <svg class="icon">
        <use xlink:href="#ride"></use>
      </svg>
      <span>{{ $t('rides') }}</span>
    </bt-menu-item>

    <bt-menu-item :to="{ name: 'shows', params: { siteId: selectedSiteId } }" v-if="hasAnyPermission(['read:show', 'write:show', 'delete:show']) && currentSite && currentSite.siteFeatures && currentSite.siteFeatures.shows">
      <svg class="icon">
        <use xlink:href="#show"></use>
      </svg>
      <span>{{ $t('shows') }}</span>
    </bt-menu-item>

    <bt-menu-item :to="{ name: 'food vendors', params: { siteId: selectedSiteId } }" v-if="hasAnyPermission(['read:fandb', 'write:fandb', 'delete:fandb']) && currentSite && currentSite.siteFeatures && currentSite.siteFeatures.foodVendors">
      <svg class="icon">
        <use xlink:href="#food"></use>
      </svg>
      <span>{{ $t('foodAndDrink') }}</span>
    </bt-menu-item>

    <bt-menu-item :to="{ name: 'opening schedules', params: { siteId: selectedSiteId } }" v-if="hasAnyPermission(['read:schedule', 'write:schedule', 'delete:schedule'])">
      <svg class="icon">
        <use xlink:href="#clock"></use>
      </svg>
      <span>{{ $t('openingHours') }}</span>
    </bt-menu-item>

    <bt-menu-item :to="{ name: 'birthdays', params: { siteId: selectedSiteId } }" v-if="hasAnyPermission(['read:birthday', 'write:birthday', 'delete:birthday']) && currentSite && currentSite.siteFeatures && currentSite.siteFeatures.birthdays">
      <svg class="icon">
        <use xlink:href="#cake"></use>
      </svg>
      <span>{{ $t('birthdays') }}</span>
    </bt-menu-item>

    <bt-sub-menu v-if="hasAnyPermission(['admin'])">
      <template v-slot:title>
        <svg class="icon">
          <use xlink:href="#gear"></use>
        </svg>
        <span>{{ $t('admin') }}</span>
      </template>

      <!--<bt-menu-item :to="{ name: 'users', params: { siteId: selectedSiteId } }">
        <span>{{ $t('users') }}</span>
      </bt-menu-item>-->

      <bt-menu-item :to="{ name: 'user types', params: { siteId: selectedSiteId } }">
        <span>{{ $t('userTypes') }}</span>
      </bt-menu-item>

      <bt-menu-item :to="{ name: 'tokens', params: { siteId: selectedSiteId } }">
        <span>{{ $t('authenticationTokens') }}</span>
      </bt-menu-item>

      <bt-menu-item :to="{ name: 'sites', params: { siteId: selectedSiteId } }">
        <span>{{ $t('sites') }}</span>
      </bt-menu-item>
    </bt-sub-menu>

    <template v-slot:footer>
      <div class="footer">
        <a href="https://my.signage.ninja/" class="sn" target="_blank">
          <img src="../assets/img/sn-button.svg" alt="signage.ninja" />
        </a>

        <a href="https://trison.uk/" target="_blank" class="bt">
          <img src="../assets/img/trison-logo.svg" alt="Trison logo" />
        </a>
      </div>
    </template>
  </bt-menu>
</template>

<script>
import BtMenu from '../components/BtMenu'
import BtMenuItem from '../components/BtMenuItem'
import BtSubMenu from '../components/BtSubMenu'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MainMenu',
  components: {
    'bt-menu': BtMenu,
    'bt-menu-item': BtMenuItem,
    'bt-sub-menu': BtSubMenu
  },
  computed: {
    ...mapState(['selectedSiteId']),
    ...mapGetters(['hasAnyPermission', 'currentSite'])
  },
  props: {
    menuVisible: Boolean // Is the main menu visible on mobile?
  }
}
</script>

<style lang="less" scoped>
.bt-menu-item svg {
  vertical-align: middle;
  margin-right: 0.5em;
  width: 1.25em;
  height: 1.25rem;
  max-height: 100%;
}

.footer {
  text-align: center;
  margin: 2rem 0;
}

.sn {
  width: 10rem;
  display: block;
  margin: 0 auto 1rem;
}

.bt {
  width: 6rem;
  display: block;
  margin: 0 auto;
}
</style>
