<template>
  <div class="site birthdays view">
    <h1 class="page-heading">
      <svg class="icon">
        <use xlink:href="#cake"></use>
      </svg>
      <span>
        {{ $t('birthdays') }}
      </span>
    </h1>

    <!--{{ birthdays }}-->

    <div class="page birthdays">
      <birthday-calendar class="panel" :birthdays="sortedBirthdays" :value="selectedDate" @input="switchDay" />

      <birthday-form class="panel" :birthdays="selectedDateBirthdays" :selectedDate="selectedDayString" :selectedDateObj="selectedDate" :unsavedChanges="unsavedChanges" @modified="unsavedChanges = $event" @save="save" />
    </div>
  </div>
</template>

<script>
import BirthdayCalendar from '../components/birthdays/BirthdayCalendar'
import BirthdayForm from '../components/birthdays/BirthdayForm'
import api from '../api'
import Loading from '../mixins/Loading'
import { mapState } from 'vuex'
import { Message, MessageBox } from 'element-ui'
import { DateTime } from 'luxon'

export default {
  name: 'Birthdays',
  components: {
    'birthday-calendar': BirthdayCalendar,
    'birthday-form': BirthdayForm
  },
  computed: {
    selectedDayString() {
      // Returns the currently selected day in 'yyyy-MM-dd' format
      if (!this.selectedDate) return null
      return DateTime.fromJSDate(this.selectedDate).toFormat('yyyy-MM-dd')
    },
    selectedDateBirthdays() {
      // Return a list of birthdays setup for the currently selected day
      if (!Object.keys(this.sortedBirthdays).length || !this.selectedDayString) {
        return []
      }

      return this.sortedBirthdays[this.selectedDayString] || []
    },
    sortedBirthdays() {
      // Return an object on birthdays sorted by day
      var results = {}

      if (!this.birthdays) return results

      this.birthdays.forEach((birthday) => {
        // Create a record for this day if one doesn't already exist
        if (!results[birthday.birthDate]) results[birthday.birthDate] = []
        results[birthday.birthDate].push(birthday)
      })

      return results
    },
    ...mapState(['selectedSiteId'])
  },
  created() {
    this.selectedDate = new Date()
    return this.loadBirthdays()
  },
  data() {
    return {
      selectedDate: null, // Currently selected date in the calendar
      unsavedChanges: false, // Are there currently unsaved changes for the current date?
      birthdays: [] // List of upcoming birthdays downloaded from the API
    }
  },
  methods: {
    save(birthdays, deletedBirthdays) {
      // Get an array of deleted birthday IDs
      var deletedIds = deletedBirthdays.map((birthday) => birthday.id)

      // Build the API requests
      var updateRequest, deleteRequest

      updateRequest = api.bulkUpdateBirthdays(
        this.selectedSiteId,
        birthdays.map((day) => day)
      )

      if (deletedBirthdays.length) {
        deleteRequest = api.bulkDeleteBirthdays(this.selectedSiteId, {
          ids: deletedIds
        })
      } else {
        deleteRequest = Promise.resolve()
      }

      return Promise.all([updateRequest, deleteRequest])
        .then(() => {
          // Updates completed. Reload the data
          Message({
            message: this.$t('savedChanges'),
            type: 'success'
          })

          return this.loadBirthdays()
        })
        .catch(() => {
          // Error saving changes
          Message({
            message: this.$t('errorSavingBirthdays'),
            type: 'error'
          })
        })
    },
    switchDay(date) {
      // Before switching to a different day check for unsaved changes and seek
      // confirmation of required
      var req

      if (this.unsavedChanges) {
        req = MessageBox.confirm(this.$t('unsavedBirthdays'), this.$t('warning'), {
          confirmButtonText: this.$t('ok'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
        })
      } else {
        req = Promise.resolve()
      }

      return req
        .then(() => {
          // User has selected okay, so switch the day
          this.selectedDate = date
        })
        .catch(() => {
          // User has selected cancel, no further action required
        })
    },
    loadBirthdays() {
      // Load the upcoming birthdays for this site
      this.startLoading()
      return api
        .getBirthdays(this.selectedSiteId)
        .then((response) => {
          this.birthdays = response.data
          this.stopLoading()
        })
        .catch(() => {
          this.stopLoading()
          Message({
            message: this.$t('errorLoadingBirthdays'),
            type: 'error'
          })
        })
    }
  },
  mixins: [Loading]
}
</script>

<style lang="less" scoped>
.page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}

@media only screen and (max-width: 1200px) {
  .page {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }
}
</style>
