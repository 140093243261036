<template>
  <div class="wrapper">
    <div class="content">
      <div class="merlin-logo-container">
        <img src="../assets/img/merlin-logo.png" />
      </div>

      <h1>{{ $t('welcome') }}</h1>
      <div class="welcome-text">
        {{ $t('enterYourEmailAddressToLogin') }}
      </div>

      <el-form ref="form" :model="formData" label-position="top">
        <!-- Username -->
        <!-- Use v-model to bind the input to the username property on line 20 -->
        <el-form-item :label="$t('emailAddress')">
          <el-input v-model="formData.username" class="username-input"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="login({ email: formData.username })">{{ $t('signIn') }}</el-button>
        </el-form-item>
      </el-form>

      <div class="poweredBy-logo-container">
        <img class="grid-item" src="../assets/img/poweredby_logo.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Input, FormItem } from 'element-ui' // Import the Input component from element UI
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  components: {
    // Register the Input component (making it available in the template)
    'el-form': Form,
    'el-input': Input,
    'el-form-item': FormItem
  },
  data() {
    // This function will make the username property available for binding to form elements
    return {
      formData: {
        username: null
      }
    }
  },
  methods: {
    ...mapActions(['login'])
  }
}
</script>

<style lang="less" scoped>
@import '../styles/vars';

h1 {
  margin: 2rem 0 0;
}

.wrapper {
  background: @login-gradient;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

// the grey color is a bit different from the design, I used the less vars here bea
.content {
  flex: 0 1 23rem;
  background: white;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  box-shadow: @panel-shadow;
  max-width: 95%;
}

.merlin-logo-container img {
  width: 6rem;
  @media (min-width: 667px) {
    width: 8rem;
  }
}

.welcome {
  margin: auto;
  text-align: center;
}

.welcome-text {
  margin-bottom: 1.5rem;
}

.poweredBy-logo-container {
  padding-top: 1rem;
}

.poweredBy-logo-container img {
  height: 1.25rem;
}

.right-panel {
  display: flex;
  margin: auto;
}

.wrapper /deep/ .el-form-item__label {
  padding-bottom: 0;
}
</style>
