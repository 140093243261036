<template>
  <div class="site shows view">
    <h1 class="page-heading">
      <svg class="icon">
        <use xlink:href="#show"></use>
      </svg>
      <span>
        {{ $t('shows') }}
      </span>
    </h1>

    <div class="page shows">
      <header>
        <el-button v-if="hasAnyPermission(['write:show'])" @click="createShow" round icon="si-plus" type="primary">
          {{ $t('createShow') }}
        </el-button>

        <el-button @click="setAllModalOpen = true" round icon="si-edit">
          {{ $t('setAllShows') }}
        </el-button>

        <el-button @click="showTypeModalOpen = true" round v-if="hasAnyPermission(['write:show_type', 'delete:show_type'])">
          {{ $t('manageShowTypes') }}
        </el-button>

        <el-button @click="showVenueModalOpen = true" round v-if="hasAnyPermission(['write:show_venue', 'delete:show_venue'])">
          {{ $t('manageShowVenues') }}
        </el-button>
      </header>

      <main class="page-body">
        <template v-if="activeShows.length">
          <h2 class="resource-type">{{ $t('activeShows') }}</h2>

          <div class="status-wrapper">
            <show-panel v-for="show in activeShows" :key="show.id" :id="show.id" :name="show.name" :showImage="show.image" :value="show.status" :messages="statusMessages" :edited="statusChanges[show.id] ? true : false" @input="updateStatus" @edit="editShow" />
          </div>
        </template>

        <template v-if="inactiveShows.length">
          <h2 class="resource-type">{{ $t('inactiveShows') }}</h2>

          <div class="status-wrapper">
            <show-panel v-for="show in inactiveShows" :key="show.id" :id="show.id" :name="show.name" :showImage="show.image" :value="show.status" :messages="statusMessages" :edited="statusChanges[show.id] ? true : false" @input="updateStatus" @edit="editShow" />
          </div>
        </template>
      </main>
    </div>

    <footer class="page-footer">
      <div class="footer-inner">
        <el-button type="primary" @click="saveStatuses" :disabled="!statusUpdates">{{ $t('save') }}</el-button>

        <span class="unsaved-notice" v-if="statusUpdates">
          <i class="si-info-circle"></i><span>{{ $t('youHaveUnsavedChanges') }}</span>
        </span>
      </div>
    </footer>

    <!-- Ride modal used for creating or editing rides -->
    <show-modal :visible="modalOpen" :ride="editedShow" :editing="modalEditing" :showTypes="showTypes" :showVenues="showVenues" @close="modalOpen = false" @save="saveShow" />

    <set-all-shows-modal :visible="setAllModalOpen" :messages="statusMessages" @close="setAllModalOpen = false" @save="setAll" />

    <message-modal :visible="messageModalOpen" :messages="statusMessages" type="shows" @close="messageModalOpen = false" @save="handleStatusMessageUpdate" />

    <show-types-modal :visible="showTypeModalOpen" :types="showTypes" @close="showTypeModalOpen = false" @save="handleShowTypeUpdate" />

    <show-venues-modal :visible="showVenueModalOpen" :venues="showVenues" @close="showVenueModalOpen = false" @save="handleShowVenueUpdate" />
  </div>
</template>

<script>
import Loading from '../mixins/Loading'
import { Message } from 'element-ui'
import api from '../api'
import { mapState, mapGetters } from 'vuex'
import ShowPanel from '../components/shows/ShowPanel'
import ShowModal from '../components/shows/ShowModal'
import SetAllShowsModal from '../components/shows/SetAllShowsModal'
import MessageModal from '../components/MessageModal'
import ShowTypesModal from '../components/shows/ShowTypes'
import ShowVenuesModal from '../components/shows/ShowVenues'

export default {
  name: 'Shows',
  components: {
    'show-panel': ShowPanel,
    'show-modal': ShowModal,
    'set-all-shows-modal': SetAllShowsModal,
    'message-modal': MessageModal,
    'show-types-modal': ShowTypesModal,
    'show-venues-modal': ShowVenuesModal
  },
  computed: {
    statusUpdates() {
      // Returns a boolean indicating if any statuses have been updated
      return Object.keys(this.statusChanges).length > 0 ? true : false
    },
    activeShows() {
      // Return a list of active shows
      return this.shows ? this.shows.filter((show) => show.active) : []
    },
    inactiveShows() {
      // Return a list of inactive shows
      return this.shows ? this.shows.filter((show) => !show.active) : []
    },
    editedShow() {
      // Returns the show currently being edited in the modal
      if (!this.shows || !this.currentId) return null
      return this.shows.find((show) => show.id === this.currentId)
    },
    ...mapState(['selectedSiteId']),
    ...mapGetters(['hasAnyPermission'])
  },
  created() {
    return Promise.all([this.loadShows(), this.loadStatusMessages(), this.loadShowTypes(), this.loadShowVenues()])
  },
  data() {
    return {
      statusChanges: {}, // Will store updated statuses before they are saved
      modalOpen: false,
      setAllModalOpen: false,
      showTypes: [], // List of available show types
      showVenues: [], // List of available show venues
      statusMessages: [], // Available ride status messages
      showTypeModalOpen: false, // Is the show type modal currently open?
      showVenueModalOpen: false, // Is the show venue modal currently open?
      messageModalOpen: false, // Is the 'Manage ride statuses' modal open?
      modalEditing: false, // Is the modal being used to edit an existing ride?
      currentId: null, // When set indicates the ride currently being edited
      shows: [] // List of shows downloaded from the API
    }
  },
  methods: {
    handleShowVenueUpdate(updatedShowVenues, deletedShowVenues) {
      // Save show venue updates
      return Promise.all([this.updateShowVenues(updatedShowVenues), this.deleteShowVenues(deletedShowVenues)])
        .then(() => {
          // Reload the venues
          return this.loadShowVenues()
        })
        .then(() => {
          Message({
            message: this.$t('updatedShowVenues'),
            type: 'success'
          })
        })
        .catch(() => {
          // If we've hit any unexpected errors saving the messages display a
          // notification to the user
          Message({
            message: this.$t('errorUpdatingShowVenues'),
            type: 'error'
          })
        })
        .finally(() => {
          this.showVenueModalOpen = false
        })
    },
    updateShowVenues(updatedShowVenues) {
      var requests = updatedShowVenues.map((venue) => {
        if (venue.id) {
          // This is an existing show venue, so update it.
          return api.updateShowVenue(this.selectedSiteId, venue.id, venue)
        } else {
          // This is a new show venue, so create it.
          return api.createShowVenue(this.selectedSiteId, venue)
        }
      })

      return Promise.all(requests)
    },
    deleteShowVenues(deletedShowVenues) {
      // Build an array of deletion requests
      var requests = deletedShowVenues
        .filter((venue) => {
          return venue.id
        })
        .map((venue) => {
          return api.deleteShowVenue(this.selectedSiteId, venue.id)
        })

      // Return a promise for the request array
      return Promise.all(requests)
    },
    handleShowTypeUpdate(updatedShowTypes, deletedShowTypes) {
      // Save show type updates
      return Promise.all([this.updateShowTypes(updatedShowTypes), this.deleteShowTypes(deletedShowTypes)])
        .then(() => {
          // Reload the types
          return this.loadShowTypes()
        })
        .then(() => {
          Message({
            message: this.$t('updatedShowTypes'),
            type: 'success'
          })
        })
        .catch(() => {
          // If we've hit any unexpected errors saving the messages display a
          // notification to the user
          Message({
            message: this.$t('errorUpdatingShowTypes'),
            type: 'error'
          })
        })
        .finally(() => {
          this.showTypeModalOpen = false
        })
    },
    updateShowTypes(updatedShowTypes) {
      var requests = updatedShowTypes.map((type) => {
        if (type.id) {
          // This is an existing show type, so update it.
          return api.updateShowType(this.selectedSiteId, type.id, type)
        } else {
          // This is a new show type, so create it.
          return api.createShowType(this.selectedSiteId, type)
        }
      })

      return Promise.all(requests)
    },
    deleteShowTypes(deletedShowTypes) {
      // Build an array of deletion requests
      var requests = deletedShowTypes
        .filter((type) => {
          return type.id
        })
        .map((type) => {
          return api.deleteShowType(this.selectedSiteId, type.id)
        })

      // Return a promise for the request array
      return Promise.all(requests)
    },
    handleStatusMessageUpdate(updatedMessages, deletedMessages) {
      // Save any changes to the ride status messages

      // Messages will be an array of message objects. Some of these may have an
      // ID (indicating they need to be updated) while some will not (indicating
      // they need to be created)
      return Promise.all([this.updateStatusMessages(updatedMessages), this.deleteStatusMessages(deletedMessages)])
        .then(() => {
          // Reload the status messages
          return this.loadStatusMessages()
        })
        .then(() => {
          Message({
            message: this.$t('updatedPresets'),
            type: 'success'
          })
        })
        .catch(() => {
          // If we've hit any unexpected errors saving the messages display a
          // notification to the user
          Message({
            message: this.$t('errorUpdatingStatusMessages'),
            type: 'error'
          })
        })
        .finally(() => {
          this.messageModalOpen = false
        })
    },
    updateStatusMessages(updatedMessages) {
      // Iterate through the updatedMessages array and send update requests for
      // messages with IDs and create requests for messages without

      var requests = updatedMessages.map((message) => {
        if (message.id) {
          // This is an existing massge, so update it.
          return api.updateStatus(this.selectedSiteId, message.id, message)
        } else {
          // This is a new message, so create it.
          return api.createStatus(this.selectedSiteId, message)
        }
      })

      return Promise.all(requests)
    },
    deleteStatusMessages(deletedMessages) {
      // Send requests to the API to delete the messages in the deletedMsssages
      // param. Note that some messages might not have an ID (indicating a
      // message was created and deleted without ever being saved) and may
      // simply be ignored.

      // Build an array of deletion requests
      var requests = deletedMessages
        .filter((message) => {
          return message.id
        })
        .map((message) => {
          console.log(message)
          return api.deleteStatus(this.selectedSiteId, message.id)
        })

      // Return a promise for the request array
      return Promise.all(requests)
    },
    loadStatusMessages() {
      return api.getStatuses(this.selectedSiteId, { type: 'shows' }).then((response) => {
        this.statusMessages = response.data
      })
    },
    loadShowTypes() {
      // Get a list of show types for the current site
      return api.getShowTypes(this.selectedSiteId).then((response) => {
        this.showTypes = response.data
      })
    },
    loadShowVenues() {
      // Get a list of show venues for the current site
      return api.getShowVenues(this.selectedSiteId).then((response) => {
        this.showVenues = response.data
      })
    },
    setAll(status) {
      // Set all the show statuses to match sthe status property
      if (!status) return

      this.shows.forEach((show) => {
        var newStatus = Object.assign({}, show.status, status)
        show.status = newStatus
        this.$set(this.statusChanges, show.id, newStatus)
      })

      Message({
        message: this.$t('allRideStatusesUpdated'),
        type: 'success'
      })

      this.setAllModalOpen = false
    },
    createShow() {
      // Open the show modal
      // Open the modal, allowing the user to create a new ride
      this.modalEditing = false
      this.modalOpen = true
      this.currentId = null
    },
    editShow(id) {
      // Open the modal
      // Open a modal allowing the user to edit an individual ride
      this.currentId = id
      this.modalEditing = true
      this.modalOpen = true
    },
    saveShow(editing, showData) {
      this.modalOpen = false
      var { id, status, ...show } = showData

      var request

      // Create an update or create request depending on if we are editing an
      // exisiting ride (editing is true)
      if (editing) {
        request = api.updateShow(this.selectedSiteId, id, show)
      } else {
        request = api.createShow(this.selectedSiteId, show)
      }

      this.startLoading()
      return request
        .then(() => {
          var message

          if (editing) {
            message = this.$t('savedChangesToShow', { showName: show.name })
          } else {
            message = this.$t('createdShow', { showName: show.name })
          }

          Message({
            message: message,
            type: 'success'
          })

          return this.loadShows()
        })
        .catch((err) => {
          console.log(err)

          Message({
            message: this.$t('errorSavingShow'),
            type: 'error'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    loadShows() {
      // laod the vendors for this site from the API
      this.startLoading()
      return api
        .getShows(this.selectedSiteId, { include: ['status', 'type', 'venue'] })
        .then((response) => {
          this.shows = response.data
          this.stopLoading()
        })
        .catch(() => {
          this.stopLoading()
          Message({
            message: this.$t('errorLoadingShows'),
            type: 'error'
          })
        })
    },
    updateStatus(status, id) {
      // Find the ride record to update
      var record = this.shows.find((show) => show.id === id)

      if (record) {
        record.status = status
        this.$set(this.statusChanges, id, status)
      }
    },
    saveStatuses() {
      // Create a request for each updatedStatus
      var requests = []

      for (var showId in this.statusChanges) {
        let request = api.setShowStatus(this.selectedSiteId, showId, this.statusChanges[showId])
        requests.push(request)
      }

      // Wait for the requests to complete
      return Promise.all(requests)
        .then(() => {
          // Display success message
          Message({
            message: this.$t('updatedShowStatuses'),
            type: 'success'
          })

          // Reset the statusChanges object
          this.statusChanges = {}
        })
        .catch(() => {
          // Display error message
          Message({
            message: this.$t('errorUpdatingShowStatuses'),
            type: 'error'
          })
        })
    }
  },
  mixins: [Loading]
}
</script>

<style lang="less" scoped>
.shows {
  header {
    margin-bottom: 1rem;

    h2 {
      color: white;
    }
  }

  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.page {
  flex-grow: 1;
}
</style>
