<template>
  <div class="site site-view view">
    <main-menu :menuVisible="menuVisible" @close="menuVisible = false" v-if="loggedIn" />

    <div class="page-wrapper">
      <header class="site-header">
        <div class="primary">
          <!-- menu toggle button -->

          <icon-button class="menu-toggle header-buttons" icon="si-ninja-star" @click="menuVisible = !menuVisible">
            <svg class="icon" style="height: 1.75rem; width: 1.75rem">
              <use xlink:href="#menu"></use>
            </svg>
          </icon-button>

          <!-- site selector component -->
          <site-select />
        </div>

        <div class="secondary">
          <!-- time and weather component -->
          <time-and-weather class="time-and-weather" v-if="loggedIn" :key="selectedSiteId" />

          <!-- logout button -->
          <div class="header-buttons">
            <el-tooltip placement="bottom" :content="$t('logout')" effect="light">
              <icon-button icon="si-power" @click="logout" />
            </el-tooltip>
          </div>
        </div>
      </header>

      <div class="content">
        <!--
          By setting the key to the selectedSiteId we force any child components
          to reload when we switch sites. This simpifies loading the new site data
        -->
        <router-view v-if="loggedIn" :key="selectedSiteId"> </router-view>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from '../components/MainMenu'
import SiteSelect from '../components/SiteSelect'
import { mapState, mapActions } from 'vuex'
import { Tooltip } from 'element-ui'
import TimeAndWeather from '../components/TimeAndWeather.vue'
import IconButton from '../components/IconButton'

export default {
  name: 'SiteView',
  components: {
    'main-menu': MainMenu,
    'el-tooltip': Tooltip,
    'site-select': SiteSelect,
    'time-and-weather': TimeAndWeather,
    'icon-button': IconButton
  },
  data() {
    return {
      menuVisible: false // Is the main menu visible on mobile?
    }
  },
  computed: {
    ...mapState(['loggedIn', 'selectedSiteId'])
  },
  methods: {
    ...mapActions(['logout'])
  }
}
</script>

<style lang="less" scoped>
@import '../styles/vars';

.site-view {
  display: flex;
  height: 100vh;
}

.page-wrapper {
  //padding: 0.5em;
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.site-header {
  display: flex;
  align-items: center;
  padding: 0.6em 1em;
  justify-content: space-between;
  background: white;
  border-bottom: 1px solid #eee;
}

.content {
  padding: 2em 1.5em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.menu-toggle {
  display: none;
}
.header-buttons {
  margin: 0 1vw 0 1vw;
}
.time-and-weather {
  // background-color: @grey11;
  display: flex;
  align-items: center;
}

.primary,
.secondary {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 950px) {
  .menu-toggle {
    display: flex;
  }
  /*
  .time-and-weather {
    display: none;
  }
  */
}

@media only screen and (max-width: 500px) {
  .time-and-weather {
    display: none;
  }
}
</style>
