<template>
  <div class="site rides view">
    <h1 class="page-heading">
      <svg class="icon">
        <use xlink:href="#ride"></use>
      </svg>
      <span>
        {{ $t('rides') }}
      </span>
    </h1>

    <div class="page rides">
      <header>
        <el-button v-if="hasAnyPermission(['write:ride'])" @click="createRide" round icon="si-plus" type="primary">
          {{ $t('createRide') }}
        </el-button>

        <el-button @click="setAllModalOpen = true" round icon="si-edit">
          {{ $t('setAllRides') }}
        </el-button>

        <el-button @click="messageModalOpen = true" round v-if="hasAnyPermission(['write:message', 'delete:message'])">
          {{ $t('managePresetMessages') }}
        </el-button>

        <icon-button style="display: none">
          <svg class="icon" style="height: 1.25rem; width: 1.25rem">
            <use xlink:href="#menu"></use>
          </svg>
        </icon-button>
      </header>

      <main class="page-body">
        <template v-if="activeRides.length">
          <h2 class="resource-type">{{ $t('activeRides') }}</h2>

          <div class="status-wrapper">
            <ride-status-panel v-for="ride in activeRides" :key="ride.id" :id="ride.id" :name="ride.name" :value="ride.status" :edited="statusChanges[ride.id] ? true : false" :messages="statusMessages" @input="updateStatus" @edit="editRide" />
          </div>
        </template>

        <template v-if="inactiveRides.length">
          <h2 class="resource-type">{{ $t('inactiveRides') }}</h2>

          <div class="status-wrapper">
            <ride-status-panel v-for="ride in inactiveRides" :key="ride.id" :id="ride.id" :name="ride.name" :value="ride.status" :edited="statusChanges[ride.id] ? true : false" :messages="statusMessages" @input="updateStatus" @edit="editRide" />
          </div>
        </template>
      </main>
    </div>

    <footer class="page-footer">
      <div class="footer-inner">
        <el-button type="primary" @click="saveStatuses" :disabled="!statusUpdates">{{ $t('save') }}</el-button>

        <span class="unsaved-notice" v-if="statusUpdates">
          <i class="si-info-circle"></i><span>{{ $t('youHaveUnsavedChanges') }}</span>
        </span>
      </div>
    </footer>

    <!-- Ride modal used for creating or editing rides -->
    <ride-modal :visible="modalOpen" :ride="editedRide" :editing="modalEditing" @close="modalOpen = false" @save="saveRide" />

    <set-all-rides-modal :visible="setAllModalOpen" :messages="statusMessages" @close="setAllModalOpen = false" @save="setAll" />

    <message-modal :visible="messageModalOpen" :messages="statusMessages" type="rides" @close="messageModalOpen = false" @save="handleStatusMessageUpdate" />
  </div>
</template>

<script>
import api from '../api'
import { mapState, mapGetters } from 'vuex'
import Loading from '../mixins/Loading'
import RideStatusPanel from '../components/Rides/RideStatusPanel'
import RideModal from '../components/Rides/RideModal'
import { Button, Message } from 'element-ui'
import SetAllRidesModal from '../components/Rides/SetAllRidesModal'
import MessageModal from '../components/MessageModal'
import IconButton from '../components/IconButton'

export default {
  name: 'Rides',
  created() {
    return Promise.all([this.loadRides(), this.loadStatusMessages()])
  },
  components: {
    'ride-status-panel': RideStatusPanel,
    'ride-modal': RideModal,
    'el-button': Button,
    'set-all-rides-modal': SetAllRidesModal,
    'message-modal': MessageModal,
    'icon-button': IconButton
  },
  computed: {
    statusUpdates() {
      // Returns a boolean indicating if any statuses have been updated
      return Object.keys(this.statusChanges).length > 0 ? true : false
    },
    activeRides() {
      // Return a list of active rides
      return this.rides ? this.rides.filter((ride) => ride.active) : []
    },
    inactiveRides() {
      // Return a list of inactive rides
      return this.rides ? this.rides.filter((ride) => !ride.active) : []
    },
    editedRide() {
      // Returns the ride currently being edited in the modal
      if (!this.rides || !this.currentId) return null
      return this.rides.find((ride) => ride.id === this.currentId)
    },
    ...mapState(['selectedSiteId']),
    ...mapGetters(['hasAnyPermission', 'blipSite'])
  },
  data() {
    return {
      queueTimeAll: null,
      openAll: null,
      blipAll: null,
      openingTimesAll: null,
      rides: null,
      modalOpen: false,
      setAllModalOpen: false,
      statusMessages: [], // Available ride status messages
      messageModalOpen: false, // Is the 'Manage ride statuses' modal open?
      modalEditing: false, // Is the modal being used to edit an existing ride?
      currentId: null, // When set indicates the ride currently being edited
      statusChanges: {} // Will store updated statuses before they are saved
    }
  },
  methods: {
    handleStatusMessageUpdate(updatedMessages, deletedMessages) {
      // Save any changes to the ride status messages

      // Messages will be an array of message objects. Some of these may have an
      // ID (indicating they need to be updated) while some will not (indicating
      // they need to be created)
      return Promise.all([this.updateStatusMessages(updatedMessages), this.deleteStatusMessages(deletedMessages)])
        .then(() => {
          // Reload the status messages
          return this.loadStatusMessages()
        })
        .then(() => {
          Message({
            message: this.$t('updatedPresets'),
            type: 'success'
          })
        })
        .catch(() => {
          // If we've hit any unexpected errors saving the messages display a
          // notification to the user
          Message({
            message: this.$t('errorUpdatingStatusMessages'),
            type: 'error'
          })
        })
        .finally(() => {
          this.messageModalOpen = false
        })
    },
    updateStatusMessages(updatedMessages) {
      // Iterate through the updatedMessages array and send update requests for
      // messages with IDs and create requests for messages without

      var requests = updatedMessages.map((message) => {
        if (message.id) {
          // This is an existing massge, so update it.
          return api.updateStatus(this.selectedSiteId, message.id, message)
        } else {
          // This is a new message, so create it.
          return api.createStatus(this.selectedSiteId, message)
        }
      })

      return Promise.all(requests)
    },
    deleteStatusMessages(deletedMessages) {
      // Send requests to the API to delete the messages in the deletedMsssages
      // param. Note that some messages might not have an ID (indicating a
      // message was created and deleted without ever being saved) and may
      // simply be ignored.

      // Build an array of deletion requests
      var requests = deletedMessages
        .filter((message) => {
          return message.id
        })
        .map((message) => {
          console.log(message)
          return api.deleteStatus(this.selectedSiteId, message.id)
        })

      // Return a promise for the request array
      return Promise.all(requests)
    },
    loadStatusMessages() {
      return api.getStatuses(this.selectedSiteId, { type: 'rides' }).then((response) => {
        this.statusMessages = response.data
      })
    },
    setAllMins(key, value) {
      this.setAll(key, value / 60)
    },
    setAll(status) {
      // Set all the ride statuses to match sthe status property
      if (!status) return

      this.rides.forEach((ride) => {
        var newStatus = Object.assign({}, ride.status, status)
        ride.status = newStatus
        this.$set(this.statusChanges, ride.id, newStatus)
      })

      Message({
        message: this.$t('allRideStatusesUpdated'),
        type: 'success'
      })

      this.setAllModalOpen = false
    },
    loadRides() {
      this.startLoading()
      return api
        .getRides(this.selectedSiteId, { include: ['status'] })
        .then((response) => {
          this.rides = response.data
          this.stopLoading()
        })
        .catch(() => {
          this.stopLoading()
          Message({
            message: this.$t('errorLoadingRides'),
            type: 'error'
          })
        })
    },
    createRide() {
      // Open the modal, allowing the user to create a new ride
      this.modalEditing = false
      this.modalOpen = true
      this.currentId = null
    },
    editRide(id) {
      // Open a modal allowing the user to edit an individual ride
      this.currentId = id
      this.modalEditing = true
      this.modalOpen = true
    },
    saveRide(editing, rideData) {
      // Update the ride described in the rideData parameter
      //console.log(rideData);
      this.modalOpen = false
      var { id, status, ...ride } = rideData

      var request

      // Create an update or create request depending on if we are editing an
      // exisiting ride (editing is true)
      if (editing) {
        request = api.updateRide(this.selectedSiteId, id, ride)
      } else {
        request = api.createRide(this.selectedSiteId, ride)
      }

      this.startLoading()
      return request
        .then(() => {
          var message

          if (editing) {
            message = this.$t('savedChangesToRide', { rideName: ride.name })
          } else {
            message = this.$t('createdRide', { rideName: ride.name })
          }

          Message({
            message: message,
            type: 'success'
          })

          return this.loadRides()
        })
        .catch(() => {
          Message({
            message: this.$t('errorSavingRides'),
            type: 'error'
          })
        })
        .finally(() => {
          this.stopLoading()
        })
    },
    updateStatus(status, id) {
      // Find the ride record to update
      var record = this.rides.find((ride) => ride.id === id)
      //if (record) this.$set(record, 'status', status);
      if (record) {
        record.status = status
        this.$set(this.statusChanges, id, status)
      }
    },
    saveStatuses() {
      // Create a request for each updatedStatus
      var requests = []

      for (var rideId in this.statusChanges) {
        let request = api.setRideStatus(this.selectedSiteId, rideId, this.statusChanges[rideId])
        requests.push(request)
      }

      // Wait for the requests to complete
      return Promise.all(requests)
        .then(() => {
          // Display success message
          Message({
            message: this.$t('updatedRideStatuses'),
            type: 'success'
          })

          // Reset the statusChanges object
          this.statusChanges = {}
        })
        .catch(() => {
          // Display error message
          Message({
            message: this.$t('errorUpdatingRideStatuses'),
            type: 'error'
          })
        })
    }
  },
  mixins: [Loading]
}
</script>

<style lang="less" scoped>
@import '../styles/vars';

.rides {
  header {
    margin-bottom: 1rem;

    h2 {
      color: white;
    }
  }

  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.page {
  flex-grow: 1;
}
</style>
